var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('portal',{attrs:{"to":"title-page"}},[_c('h1',[_vm._v("Reporte de clientes por fecha")])]),(_vm.items != null)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"item-key":"id","search":_vm.search,"fixed-header":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-top-bar"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}],null,false,4071162091),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}],null,false,989694948),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-text-field',{attrs:{"label":"Buscar","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-btn',{staticClass:"mt-md-3",attrs:{"small":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.searchReservations()}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Buscar")],1),_c('download-excel',{attrs:{"name":("customers_" + _vm.startDate + "_" + _vm.endDate),"data":_vm.items,"fields":_vm.fieldsExcell}},[_c('v-btn',{staticClass:"mt-md-3 ml-2",attrs:{"small":"","elevation":"2","color":"success"}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.customerName)+" "+_vm._s(item.customerLastname))])]}},{key:"item.customerPhone",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.customer.formatInternational))])]}},{key:"item.customerCountry",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.customer.countryCode))])]}}],null,false,2309301819),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }