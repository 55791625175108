<template>
  <div style="">
    <portal to="title-page">
      <h1>Reporte de clientes por fecha</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      :loading="loading"
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="3"
            >
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                v-model="search"
                label="Buscar"
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="d-flex">
              <v-btn class="mt-md-3" small @click="searchReservations()"
                elevation="2"
                color="primary"
              ><v-icon>mdi-magnify</v-icon> Buscar</v-btn>
              <download-excel
                :name="`customers_${startDate}_${endDate}`"
                :data="items" 
                :fields="fieldsExcell">
                <v-btn class="mt-md-3 ml-2" small
                  elevation="2"
                  color="success"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-col>
            <!-- <v-col cols="6" sm="6" md="6">
              <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
            </v-col> -->
          </v-row>
        </div>
        <v-divider></v-divider>
        <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
      </template>
      <template v-slot:item.code="{ item }">
        {{item.code}}
      </template>
      <template v-slot:item.customer="{ item }">
        <div >{{item.customerName}} {{item.customerLastname}}</div>
      </template>
      <template v-slot:item.customerPhone="{ item }">
        <div >{{item.customer.formatInternational}}</div>
      </template>
      <template v-slot:item.customerCountry="{ item }">
        <div >{{item.customer.countryCode}}</div>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import Vue from "vue";
  import JsonExcel from "vue-json-excel";
 
  Vue.component("downloadExcel", JsonExcel);
  import Reservation from "@/models/Reservation";
  import { /*mdiClose, mdiArrowLeft*/mdiPencil, mdiCheckboxBlankCircleOutline, mdiCheckCircle } from '@mdi/js';
  // import {mapState} from 'vuex'
  // import firebase from 'firebase/app'
  // import 'firebase/auth'

  // const axios = require('axios');

  export default {
    name:'CustomersReport',
    components:{},
    data() {
      let date = this.$moment()//.add(1, 'day');
      return {
        mdiPencil:mdiPencil,
        mdiCheckboxBlankCircleOutline:mdiCheckboxBlankCircleOutline,
        mdiCheckCircle:mdiCheckCircle,
        //mdiClose:mdiClose,
        //mdiArrowLeft:mdiArrowLeft,
        //date:'',
        startDate:date.format('YYYY-MM-DD'),
        endDate:this.$moment().add(1, 'day').format('YYYY-MM-DD'),
        reservationModel:new Reservation,
        items:null,
        excelData:[],
        unsubscribe:null,
        search:'',
        selected:[],
        loading:true,
        dialogConfirmDeleteReservation:false,
        loadingDeleteReservation:false,
        tableHeaders:[
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'code',
            width:"140px"
          },
          {
            text: 'Email',
            align: 'start',
            sortable: true,
            value: 'customerEmail',
          },
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'customer',
          },
          {
            text: 'Tel.',
            align: 'start',
            sortable: true,
            value: 'customerPhone',
          },
          {
            text: 'País',
            align: 'start',
            sortable: true,
            value: 'customerCountry',
          },
        ],
        fieldsExcell:{
          'ID': 'code',
          'Email': 'customerEmail',
          'Nombre': {
            field: 'customer',
            callback:(value) => {
              return `${value.name} ${value.lastname}`
            }
          },
          'Tel': {
            field: 'customer',
            callback:(value) => {
              return value.formatInternational
            }
          },
          'Pais': {
            field: 'customer',
            callback:(value) => {
              return value.countryCode
            }
          },
        },
        menu1: false,
        menu2: false,
        dialog: false,
        reservation: null,
        snackbar:{
          show:false,
          color:'',
          message:''
        },
      };
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Admin')
    },
    mounted() {
      this.searchReservations();
    },
    methods:{
      searchReservations() {
        if (this.unsubscribe) {
          this.unsubscribe();
        }
        this.loading = true;
        this.unsubscribe = this.reservationModel.findByRangeDates(this.$moment(this.startDate), this.$moment(this.endDate)).onSnapshot(snap => {
          this.items = [];
          this.excelData = []
          snap.forEach(doc => {
            const data = doc.data()
            this.items.push(data);
            /*this.excelData.push({
              id:data.code,
              email: data.customerEmail,
              nombre: `${data.customerName} ${data.customerLastname}`,
              tel: data.customer.formatInternational,
              pais: data.customer.countryCode,
            })*/
          })
          this.loading = false;
        })
      },
      openModalConfirmationInfo(reservation) {
        this.reservation = reservation
        this.$store.dispatch('reservation/setDialogConfirmationInfo', true)
      },
      confirmDelete(r, e) {
        e.preventDefault()
        this.reservation = r
        this.dialogConfirmDeleteReservation = true
      },
      deleteReservation() {
        this.loadingDeleteReservation = true
        this.reservationModel.delete(this.reservation.id)
          .then(async (response) => {
            if (response.status == 'OK') {
              this.dialogConfirmDeleteReservation = false 
              this.loadingDeleteReservation = false
              this.reservation = null
              this.showSnackbarSuccess('La reservación se ha eliminado correctamente')
            } else {
              //this.dialogConfirmDeleteReservation = true 
              this.loadingDeleteReservation = false
              this.showSnackbarError('Error al eliminar la reservación. Si el problema persiste contacte al Administrador')
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingDeleteReservation = false
            this.showSnackbarError(error.message)
          });
      },
      redirectTo(url, event) {
        if (event) {
          event.preventDefault();
        }
        this.$router.push({path:url})
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    //computed: mapState(['dialogConfirmationInfo'])
  }
</script>